$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            } else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function () {
        $(this).toggleClass("active");
        $(this).next(".js-nav").toggleClass("active")
        $("body").toggleClass("freeze")
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-accordion-item").click(function () {
        // $(this).siblings().removeClass("active").find(".js-accordion-body").slideUp();
        $(this).toggleClass("active");
        $(this).find(".js-accordion-body").slideToggle();
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * slider google-reviews
    // *
    // *
    const swiper = new Swiper('.js-slider-google-reviews', {
        // Optional parameters
        loop: true,
        slidesPerView: 3,
        spaceBetween: 20,
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            800: {
              slidesPerView: 3,
              spaceBetween: 20
            },

            500: {
              slidesPerView: 2,
              spaceBetween: 15
            },
            100: {
                slidesPerView: 1,
                spaceBetween: 10
              }
          }

    });

    // * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    // * dialog window
    // *
    // *
    // news
    var newsDialog = $(".js-news");
    var newsState = sessionStorage.getItem('newsState');

    $(".js-close-news").click(function () {
        newsDialog.addClass("close");
        sessionStorage.setItem('newsState', true);
        $(this).parents(".js-news").get(0).close();
        $("body").removeClass("freeze");
    });

    if (!newsState && newsDialog.length > 0) {
        newsDialog.get(0).showModal();
        $("body").addClass("freeze");
    }

    // apply
    var applyDialog = $(".js-apply");
    var formSuccessDialog = $(".js-form-success");

    $(".js-close-apply").click(function () {
        applyDialog.addClass("close");
        applyDialog.get(0).close();
        $("body").removeClass("freeze");
    });

    $(".js-close-form-success").click(function () {
        formSuccessDialog.addClass("close");
        formSuccessDialog.get(0).close();
        $("body").removeClass("freeze");
    });

    $(".js-open-apply").click(function () {
        applyDialog.removeClass("close");
        applyDialog.get(0).showModal();
        $("body").addClass("freeze");
    });

    var formFieldError = $(".form__field--error");
    if (formFieldError.length > 0) {
        applyDialog.get(0).showModal();
        $("body").addClass("freeze");
    }

    document.addEventListener('fetchit:after', (e) => {
        const { response } = e.detail;

        // console.log(response.success); // true|false
        // console.log(response.message); // Message from the server
        // console.log(response.data); // Data from the server
        if (response.success === true) {
            if(applyDialog.length > 0){
                applyDialog.addClass("close");
                applyDialog.get(0).close();
            }

            formSuccessDialog.removeClass("close");
            formSuccessDialog.get(0).showModal();
            $("body").addClass("freeze");

        }
    });


});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= 58) {
        $(".js-header").addClass("sticky");
        $(".js-header").next().addClass("stickyPadding");
    } else {
        $(".js-header").removeClass("sticky");
        $(".js-header").next().removeClass("stickyPadding");
    }
});

